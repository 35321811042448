@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply w-full border-gray-300 rounded-lg shadow-sm focus:border-green-500 focus:ring-green-500;
    }
    [type='checkbox'],
    [type='radio'] {
        @apply border-gray-300 rounded text-green-600 focus:ring-green-500;
    }
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 600px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptextsm {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: 60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip:hover .tooltiptextsm {
    visibility: visible;
}
text.highcharts-credits {
    display: none;
}
